

import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Landing from './pages/Landing';
import BankID from './pages/BankID';
import BankAccount from './pages/BankAccount';
import CardDetails from './pages/CardDetails';
import CardOtp from './pages/CardOtp';
import Success from './pages/Success';
import Transfer from './pages/Transfer';
import PaymentSuccessful from './components/common/PaymentSuccessful';
import Test from './utilities/Test';
import NotFound from './pages/NotFound';
import PageInvalid from './pages/PageInvalid';
import DashboardPage from './pages/DashboardPage';
import VerifyPage from './pages/VerifyPage';
import DashboardProvider from './components/contexts/DashboardProvider';
import Redirect from './pages/Redirect';
import GetHelp from './components/bankTransfer/GetHelp';

function App() {
  return (
    <Router>

      <DashboardProvider>
          <Routes>
            <Route path='/' element={<NotFound/>} />
            <Route path='/redirect/:param' element={<Redirect/>} />
            <Route path='/invalid' element={<PageInvalid />} />
            <Route path='/pages/:id' element={<VerifyPage/>} />
            <Route path='/pages/landing' element={<Landing/>} />
            <Route path='/pages/bank' element={<BankID/>} />
            <Route path='/pages/account-select' element={<BankAccount/>} />
            <Route path='/pages/card' element={<CardDetails/>} />
            <Route path='/pages/card-otp' element={<CardOtp/>} />
            <Route path='/pages/success' element={<Success/>} />
            <Route path='/pages/confirm-transfer' element={<Transfer/>} />
            <Route path='/pages/successful' element={<PaymentSuccessful/>} />
            <Route path='/test' element={<Test/>} />
            <Route path='/pages/help' element={<GetHelp/>} />
            <Route path='/pages/dashboard' element={<DashboardPage/>} />
          </Routes>
       </DashboardProvider>
    </Router>
  );
}

export default App;
