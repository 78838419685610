import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line
import { copyToClipboard } from '../../utilities/clipboardUtils'
import './banktransfer.scss'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { DotLoader } from 'react-spinners';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';


// data displayed when show account is clicked on confirmation //
const OverlaySlider = styled.div`
position:"absolute";
top:0;
left:0;
background: transparent;
height: 100%;
width: 100%;
z-index: 3;
display: flex;
align-items: center;
justify-content: center;
padding-top:1rem;
`

function BankTransfer({ hideSendMoneyButton,nubanList,transferId,setNubanActive}) {  
  // eslint-disable-next-line
  const refcopy  = useRef(null);
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState('');
  const [transferData,setTransferData] = useState({})
  const [isLoading,setIsLoading] = useState(false);
  const dashData = JSON.parse(localStorage.getItem('dashResult'))
  const auth = JSON.parse(localStorage.getItem('auth'))
  const [erro,setError] = useState(false);
  const [isNuban,setIsNuban] = useState(false)
  const [expireTime,setExpireTime] = useState(null);
  // eslint-disable-next-line
  const [value, setValue] = useState('');
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);

  // Function to handle the change in the select input
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClick = () => {
    navigate("/pages/confirm-transfer",{state:{transferData}});
  };
  const initializedata = JSON.parse(localStorage.getItem('dashResult'))
  let isDashboard = auth?.source === "dashboard";
  let timexp = new Date(expireTime);
  let formattedTime = timexp.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  const generateNuban = async ()=>{
    let transData;
    setIsLoading(true);
    
    try{

      const headers = {  
        Authorization:auth?.livePublicKey
      }
      if(selectedValue){
        if(isDashboard){
          transData = {
            transactionId:transferId || initializedata?.transactionId,
            nubanType: "temporary",
            email:dashData.cust_email,
            amount: Number(dashData?.amount)/100,
            currency_settled:dashData?.currency,
            bank:selectedValue 
          }  
        }else{
          transData = {
            transactionId: auth.transactionId,
            nubanType: "temporary",
            email:auth?.email,
            amount: Number(auth?.amount)/100,
            currency_settled:auth?.currency,
            bank:selectedValue
          } 
        }
        
        const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create"
        let res = await axios.post(url,{...transData},{
          headers:headers
        });
        setExpireTime(res?.data?.responseData?.data?.expires_at)
        // setTransferId(initializedata?.transactionId);
         const value = res?.data?.responseData?.data;
         const combinedData = {
           ...value,
           transactionId: transferId || auth.transactionId || initializedata?.transactionId
         }
         localStorage.setItem('nubanTrans', value.transactionId);      
         setTransferData(combinedData)
         
         setIsLoading(false)
         setIsNuban(true)
         setNubanActive(true)
         console.log("nuban",res.data)
      }

    }catch(err){
      setIsLoading(false)
      setIsNuban(false)
      setError(true)
      console.log(err)
    }
  }

  useEffect(()=>{
    generateNuban();
    // eslint-disable-next-line
  },[selectedValue])

  useEffect(()=>{
    setTimeout(()=>setCopied(false),1000)
  },[copied])
  
  return (
    <div className='bank_transfer'>
     
      <div className='rule'></div>
      <div className='bank_transfer_title_container'>
        <h3 className='bank_transfer_title'>Bank details</h3>
        <div className='transfer_bank_select'>
          
          {
            (selectedValue !=="gtbank" && selectedValue !== "kuda_bank" && selectedValue !=="fcmb_bank") ?
            <img src="/images/banks/bankIcon.svg" alt='' />:
            <>
            
            <img src={ `/images/banks/${selectedValue}.svg`} alt='' />
            </>
          }
          <select className="mySelect" value={selectedValue} onChange={handleSelectChange} disabled={isNuban}>
          
            <option className='select_dis' value="" disabled >Select bank</option>
             {
              Array.isArray(nubanList) && nubanList.length > 0 && nubanList.map((nuban,index)=>(

                <option key={index} value={nuban?.bank}>
                  {
                    nuban?.bank === "gtbank" ? "GTBank" 
                    : nuban?.bank === "kuda_bank" ? "Kuda Bank"
                    : nuban?.bank === "fcmb_bank" ? "FCMB"
                    : nuban?.name
                  }
                </option>
              ))
             }
             
          </select>

        </div>
      </div>
      {
        !selectedValue &&
        <>
        </>
       
      }
      {
        selectedValue &&
        <div className='bank_transfer_content'>
          {
            isLoading ?
            <OverlaySlider>
              <DotLoader color="#259792" /> 
            </OverlaySlider>
            : erro ? 
            <h1 className='bank_transfer_title'>Fail to Generate nuban! Please try again</h1>:
            <>
              <div className='bank_row '>
                <span className='bank_light'>Account number</span>
                <span className='bank_light'>Bank</span>
              </div>

              <div style={{marginBottom:"20px"}} className='bank_row'>
                <div className='copy_div'>
                  {
                    copied ?
                    <input 
                    
                    type='text' 
                    value={"copied"} 
                    className='copyInput copyInput_widthTest'
                    onChange={({ target: { value } }) => setValue(value, setCopied(false))}
                    />:
                    <input 
                    
                    type='text' 
                    value={transferData.account_number} 
                    className='copyInput copyInput_width'
                    onChange={({ target: { value } }) => setValue(value, setCopied(false))}
                    />
                  }

                    <CopyToClipboard text={transferData.account_number} onCopy={() => setCopied(true)}>
                       <img src="/images/copy.svg" alt='copy' className='copyIcon'/>
                    </CopyToClipboard>
                   
                   
                </div>
                <span className='bank_thick'>{transferData.bank}</span>
              </div>


              <div className='bank_row '>
                <p className='bank_light'>Account type</p>
                <p className='bank_light'>Account name</p>
              </div>

              
              <div className='bank_row'>
                <span><img src='/images/Badge.svg' alt='badge' /></span>
                <span className='bank_thick'>{transferData.account_name}</span>
              </div>
             
            </>
          }

        </div>
      }
      { selectedValue && !isLoading &&
         <p className='bank_time'>This account details is only valid for this specific transaction and it’ll expire by <span style={{color:"#2EBDB6"}}>{formattedTime}</span> </p>
      }

       {!hideSendMoneyButton && selectedValue && !isLoading && (
          <button className="btn" onClick={handleClick} style={{marginTop:"16px"}}>
            I’ve sent the money
          </button>
        )}
    </div>
  )
}

export default BankTransfer
