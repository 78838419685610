import React, { useContext, useEffect, useState } from 'react'
import '../components/layout/layout.scss'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import DashboardContext from '../components/contexts/DashboardContext';

function DashboardPage() {
    const auth = JSON.parse(localStorage.getItem('auth'))
    useEffect(()=>{
        const isDashboard = JSON.parse(localStorage.getItem('auth'))
        if(isDashboard.source !== "dashboard") return navigate('/')
        // eslint-disable-next-line
    },[])
    const [isLoading, setIsLoading] = useState(false);
    const data = JSON.parse(localStorage.getItem('auth'))
    // console.log("*****DashboardPage*******", auth)
    // eslint-disable-next-line
    const [selectedOption, setSelectedOption] = useState('');
    const [dataInput,setData] = useState({
        firstName:"",
        lastName:"",
        cust_email:"",
        Phoneno:"",
        currency: (data?.isFixedAmount && data?.amount !== "0") ? data?.currency : "NGN",
        amount:(data?.isFixedAmount && data?.amount !== "0") ? String(Number(data?.amount)/100) : "",
        channel: "dashboard",
        access_code:data?.access_code,
        url:`https://checkout.paywithflick.co/pages/${data?.access_code}`

    })
    const {setDashData} = useContext(DashboardContext);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
          ...dataInput,
          [name]: value,
        });
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        
        setIsLoading(true)
        try{
            const headers = {  
                Authorization:`Bearer ${auth?.livePublicKey}`
            }
            
            const result ={
                firstName:dataInput?.firstName,
                lastName:dataInput?.lastName,
                cust_email:dataInput?.cust_email,
                Phoneno:dataInput?.Phoneno,
                currency: data?.currency,
                currency_collected: data?.currency || data?.currency_collected,
                currency_settled:data?.currency_settled,
                amount:(data?.isFixedAmount && data?.amount !== "0") ? data?.amount :  String(Number(dataInput?.amount)*100),
                channel: "dashboard",
                access_code:data?.access_code,
                url:`https://checkout.paywithflick.co/pages/${data?.access_code}`,
            }
            console.log("Dashboard Output: ",result);
            const url ="https://flick-app.com/collection/create-charge"
            let res = await axios.post(url,{...result},{
              headers:headers
            }); 

             setIsLoading(false)
             const dashResult = {...result,transactionId:res.data?.data?.transactionId,cbInfo:res.data?.data}
             console.log("result value: ",res?.data)
             console.log("dashboard response",dashResult);
             const localDashData = JSON.stringify(dashResult)
             localStorage.setItem("dashResult",localDashData)
             setDashData(dashResult);
             toast.success(res.data?.message);   
             navigate("/pages/landing")
             
        }catch(err){
            setIsLoading(false)
            console.log(err)
            const errorMessage = err?.response?.data?.message || "Server Error";
            toast.error(errorMessage);
        }
    }

 
    const businessName = auth?.business_name;
    let businessName2 = businessName.split(' ')[0];
    console.log(businessName2);

  return (
    <div className='dashboard'>
      <div className='dashboard_container'>
        <div className='dashboard_log'>
            {/* <img src='/images/logoFlick.svg' alt='logo'/> */}
            <h1 className='dashboard_desc'><b>{businessName2}</b></h1>
        </div>

        <form onSubmit={handleSubmit} className='form_group'>
            <div className='inputs_container'>
                <input className='inputs_group halved focuse' type='text' placeholder='First Name' name='firstName'  onChange={handleChange} required/>
                <input className='inputs_group halved focuse' type='text' placeholder='Last Name'  name='lastName'  onChange={handleChange} required/>
            </div>
            <input className='inputs_group focuse' type='text' placeholder='Email Address'  name='cust_email'  onChange={handleChange} required />
            <input className='inputs_group focuse' type='text' placeholder='Phone Number' name='Phoneno'  onChange={handleChange} required />

            <div className='charge_amout_container'>
                <div className='charge_amount'>
                    <img src={`/images/${(data?.isFixedAmount && (data?.amount !== "0" || data?.amount !==""))? data?.currency+".svg" : data?.currency}.svg`} alt=''/>
                    <select className="charge_amount_select" value={dataInput?.currency} onChange={handleChange} name='currency' disabled={true}>
                    {
                        (data?.currency_settled == data?.currency) &&
                        <option value={data?.currency}>{data?.currency}</option>
                       }

                       {
                        (data?.isFixedAmount && (data?.amount !== "0" || data?.amount !=="")) &&
                        <option value={data?.currency}>{data?.currency+"/"+data?.currency_settled}</option>
                       }

                         {/* <option value="NGN">NGN</option>
                         <option value="USD">USD</option>
                         <option value="GBP">GBP</option>
                         <option value="CAD">CAD</option> */}

                       {
                        (data?.currency_settled) &&
                        <option value={data?.currency_settled}>{data?.currency+"/"+data?.currency_settled}</option>
                       }
                    </select>
                </div>
                {/* <input style={{width:"70%"}} className={`inputs_group ${data?.isFixedAmount ? "fixed_amount": ""}`} value={`${dataInput?.amount ? (Number(dataInput?.amount)/100): dataInput?.amount} `} type='text' placeholder='Amount to charge' onChange={handleChange} name='amount' readOnly={data?.isFixedAmount}/> */}
                <input style={{width:"70%"}} className={`inputs_group ${(data?.isFixedAmount && data?.amount !=="0")? "fixed_amount": ""}`} value={dataInput?.amount} type='text' placeholder='Amount to charge' onChange={handleChange} name='amount' readOnly={(data?.isFixedAmount && data?.amount !=="0")}/>

            </div>

            <button className='btn' style={{padding:"14px",background:"#2EBDB6"}}>
                {isLoading ? (
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />              ) : (
                    <>   
                    Pay Now <img style={{display:"inline"}} src="/images/arrowForward.svg" alt=''/>
                    </>
                )}
            </button>

        </form>
      </div>
    </div>
  )
}

export default DashboardPage
