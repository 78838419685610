import React from 'react'
import './landing.scss'
import PaymentOptions from '../../utilities/PaymentOptionData.json'
function SelectDropDown({ handleBankSelection, selectedBank,isNubanActive }) {
  const auth = JSON.parse(localStorage.getItem('auth'))
  const setting   = auth.checkout_settings
  const allPaymentOptions = PaymentOptions;
  const filteredPaymentOptions = setting
    ? allPaymentOptions.filter((bank) => {
        if (bank.name === "Card" && setting.card) return true;
        if (bank.name === "Transfer" && setting.bank_transfer) return true;
        return false;
      })
    : allPaymentOptions;
  return (
    <div className="bank-list">
      <div className="modal-container">
        <div className="scrollable-list">
          {filteredPaymentOptions.map((bank) => (
            <div
              key={bank.id}
              className={`icon-bank ${bank === selectedBank ? "selected" : ""}`}
              onClick={() => handleBankSelection(bank)}
            >
              <div className="bank-name-icon">
                <div style={{display:"flex",gap:"10px"}}>
                    <img src={bank.image} alt="icon"/>
                    <h2 className="bank-name">{bank.name}</h2>
                </div>
                {bank === selectedBank && (
                  <img
                    src={process.env.PUBLIC_URL + "/images/bank-check.svg"}
                    alt="check"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ... */}
    </div>
  )
}

export default SelectDropDown
